.banner {
  height: 800px;
  position: relative;
  padding: 0 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: -1px;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  animation: bgAnimation 30s infinite;
  opacity: 0;
}

.bg:nth-child(1) {
  background-image: url("./assets/bg1.webp");
}

.bg:nth-child(2) {
  background-image: url("./assets/bg2.jpeg");
  animation-delay: 10s;
}

.bg:nth-child(3) {
  background-image: url("./assets/bg3.jpeg");
  animation-delay: 20s;
}

@keyframes bgAnimation {
  0%,
  100% {
    opacity: 0;
    transform: scale(1);
  }

  1% {
    opacity: 1;
    transform: scale(1);
  }

  32.333% {
    opacity: 1;
    transform: scale(1.1);
  }

  34.333% {
    opacity: 0;
    transform: scale(1.1);
  }

  99% {
    opacity: 0;
    transform: scale(1);
  }
}

.effect {
  position: absolute;
  transform: rotate(180deg);
  bottom: -1px;
  left: 0;
  right: 0;
}

.effect path {
  fill: #fff;
}

.filter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
}

.title {
  position: relative;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
  text-align: start;
}

.subtitle {
  position: relative;
  color: var(--accent-color);
  margin-bottom: 50px;
}

.button {
  position: relative;
}

.about {
  padding: 100px 150px;
  position: relative;
  background-color: #fff;
}

.aboutTitle {
  margin-bottom: 50px;
}

.aboutBody {
  display: flex;
}

.aboutBody > * {
  width: 50%;
}

.aboutImageContainer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutImage {
  width: 75%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.88);
  border-radius: 20px;
}

.aboutItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.choose {
  padding: 160px 110px 110px;
  position: relative;
  background-color: var(--main-color);
}

.chooseEffect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.chooseEffect path {
  fill: #fff;
}

.chooseTitle {
  color: var(--accent-color);
  margin-bottom: 30px;
}

.line {
  margin-bottom: 35px;
}

.line::before,
.line::after {
  background-color: var(--accent-color);
}

.chooseItem {
  padding: 10px;
  max-width: 570px;
}

.chooseItemTitle {
  font-family: "Roboto", Sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 32px;
  text-align: center;
  line-height: 1.3em;
  margin-bottom: 20px;
}

.chooseItemText {
  color: var(--accent-color);
  text-align: center;
}

.chooseItemsRow {
  display: flex;
  justify-content: center;
}

.row2items > * {
  width: 50%;
}

.row3items > * {
  width: 33.3333%;
}

.chooseItemsRow:first-child {
  margin-bottom: 100px;
}

.chooseItems {
  margin-bottom: 50px;
}

.chooseButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.chooseButtons > * {
  height: 68px;
}

@media (max-width: 1160px) {
  .banner {
    padding: 0 70px;
  }

  .about {
    padding: 6% 70px;
  }

  .choose {
    padding: 160px 70px 80px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: fit-content;
    padding: 30px 30px 100px;
  }

  .about {
    padding: 30px;
  }

  .aboutBody {
    flex-direction: column;
  }

  .aboutBody > * {
    width: 100%;
  }

  .aboutImageContainer {
    margin-bottom: 30px;
  }

  .aboutImage {
    width: 100%;
  }

  .choose {
    padding: 80px 30px 30px;
  }

  .chooseItemsRow {
    flex-direction: column;
    gap: 20px;
  }

  .row2items > *,
  .row3items > * {
    width: 100%;
  }

  .chooseItemsRow:first-child {
    margin-bottom: 20px;
  }

  .chooseButtons {
    display: block;
  }
}
