.root {
  border: 3px solid var(--accent-color);
  border-radius: 5px;
  background-color: var(--accent-color);
  padding: 15px 20px;
  color: #fff;
  letter-spacing: 1.5px;
  font-size: 15px;
  text-transform: uppercase;
  transition: transform 0.28s ease-in;
  font-weight: 500;
  line-height: 1;
}

.root:hover {
  transform: translateY(-8px);
  background-color: transparent;
  color: var(--accent-color);
}
