.banner {
  height: 500px;
  background-image: url("./assets/bg.webp");
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}

.title {
  color: #fff;
  margin-bottom: 15px;
  position: relative;
}

.subtitle {
  color: #fff;
  font-size: 17px;
  position: relative;
}

.form {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 100px 20px;
}

@media (max-width: 1140px) {
  .banner {
    height: 300px;
  }

  .form {
    padding: 50px 20px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: fit-content;
    padding: 70px 20px;
  }
}
