.root {
  padding: 150px;
  background-image: url("./assets/bg.jpg");
  height: 100%;
}

.title {
  margin-bottom: 35px;
}

.line {
  margin-bottom: 85px;
}

.row {
  display: flex;
  justify-content: center;
}

.row2items > * {
  width: 50%;
}

.row3items > * {
  width: 33.3333%;
}

.row:first-child {
  margin-bottom: 100px;
}

.row3items > *:nth-child(2) {
  padding-top: 110px;
}

.item {
  padding: 10px;
  max-width: 570px;
}

.itemTitle {
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  line-height: 1.3em;
  margin-bottom: 20px;
}

.itemText {
  text-align: center;
  font-size: 17px;
}

@media (max-width: 1140px) {
  .root {
    padding: 100px 70px;
  }

  .title {
    margin-bottom: 25px;
  }

  .line {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 50px 30px;
  }

  .row {
    flex-direction: column;
    gap: 20px;
  }

  .row2items > *,
  .row3items > * {
    width: 100%;
  }

  .row:first-child {
    margin-bottom: 20px;
  }

  .row3items > *:nth-child(2) {
    padding-top: inherit;
  }
}
