.root {
  padding: 10px 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: var(--header-z-index);
}

.left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.menuButton {
  display: none;
  width: 22px;
  height: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.menuButton div {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

.menuButtonOpened div:nth-child(1) {
  transform: rotate(-45deg) translate(-6px, 6px);
}

.menuButtonOpened div:nth-child(2) {
  opacity: 0;
}

.menuButtonOpened div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}

.navigation {
  display: flex;
  gap: 60px;
  font-size: 17px;
  font-weight: 600;
}

.link {
  color: var(--main-color);
  padding: 15px;
  position: relative;
  line-height: 1;
  transition: color 0.17s;
}

.link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: var(--light-main-color);
  transform: scaleX(50%) translateY(2px);
  opacity: 0;
  transition: transform 0.17s, opacity 0.17s;
}

.link:hover,
.link:global(.active) {
  color: var(--light-main-color);
}

.link:hover::after,
.link:global(.active)::after {
  transform: none;
  opacity: 1;
}

@media (max-width: 1280px) {
  .root {
    padding: 10px 60px;
  }

  .logo {
    height: 65px;
  }

  .navigation {
    gap: 30px;
  }
}

@media (max-width: 1140px) {
  .logo {
    height: 50px;
  }

  .navigation {
    gap: 30px;
  }

  .menuButton {
    display: flex;
  }

  .navigation {
    display: none;
    background: #fff;
    position: absolute;
    top: 100%;
    flex-direction: column;
    left: 0;
    right: 0;
    gap: 0;
  }

  .navigation.menuOpened {
    display: flex;
  }

  .link {
    border-bottom: 1px solid #c4c4c4;
    padding: 15px 60px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 10px 20px;
  }

  .link {
    padding: 15px 20px;
  }
}
