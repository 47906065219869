.root {
  padding: 10px 160px;
  background-color: #154360a1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-bottom: 20px;
}

.logo {
  width: 50px;
}

.p {
  padding-bottom: 25px;
}

@media (max-width: 1160px) {
  .root {
    padding: 10px 70px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 10px 30px 30px;
    flex-direction: column;
  }
}
