.header {
  cursor: pointer;
  border-bottom: 1px solid #d5d8dc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  line-height: 1;
  text-decoration: underline;
  padding: 15px;
  transition: color 0.3s;
}

.arrow {
  border: 5px solid transparent;
  border-left-color: #000;
  transition: transform 0.3s;
}

.opened .arrow {
  transform: rotate(90deg);
}

.contentWrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-out;
}

.content {
  padding: 15px;
  border-bottom: 1px solid #d5d8dc;
}

.opened .header {
  border-bottom: none;
  margin-bottom: 1px;
}

.opened .title {
  color: var(--light-main-color);
}

.opened .contentWrapper {
  max-height: 1000px;
  transition: max-height 2s ease-in;
}
