.root {
  padding: 150px;
  background-image: url("./assets/bg.jpg");
  height: 100%;
}

.title {
  margin-bottom: 35px;
}

.line {
  margin-bottom: 85px;
}

.section {
  display: flex;
}

.section > * {
  width: 50%;
}

.article {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}

.imageContainer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 75%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.88);
  border-radius: 20px;
}

@media (max-width: 1140px) {
  .root {
    padding: 100px 70px;
  }

  .title {
    margin-bottom: 25px;
  }

  .line {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 50px 30px;
  }

  .section {
    flex-direction: column;
    gap: 30px;
  }

  .section > * {
    width: 100%;
  }

  .title {
    margin-bottom: 20px;
  }

  .line {
    margin-bottom: 40px;
  }

  .image {
    width: 100%;
  }
}
