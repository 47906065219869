.root {
  padding-top: 95px;
  flex-grow: 1;
}

@media (max-width: 1280px) {
  .root {
    padding-top: 85px;
  }
}

@media (max-width: 1140px) {
  .root {
    padding-top: 70px;
  }
}
