.root {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.submit {
  font-size: 1em;
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 10px 15px;
  align-self: flex-start;
}

.inputRoot {
  font-family: "Roboto", Sans-serif;
}

.label {
  font-size: 16px;
  font-weight: 700;
  color: rgb(75, 79, 88);
  margin-bottom: 4px;
}

.label span {
  color: red;
  font-weight: 400;
}

.input {
  display: block;
  padding: 6px 10px;
  border: 1px solid #ccc;
  line-height: 1.3;
  font-size: 16px;
  width: 100%;
}

.inputError {
  border-color: #990000;
}

.input:focus {
  outline: none;
}

.error {
  margin-top: 8px;
  font-size: 12px;
  color: #990000;
}
