:root {
  --main-color: #154360;
  --light-main-color: #154360c4;
  --accent-color: #60ce80;

  --header-z-index: 1;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-size: 14px;
  font-family: "Roboto Slab", serif;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

svg,
img {
  display: block;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

li {
  list-style: none;
}

h1 {
  font-size: 70px;
  line-height: 1.3em;
  font-weight: 600;
  text-align: center;
}

h2 {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  line-height: 1.3em;
}

h3 {
  font-size: 25px;
  font-weight: 600;
  line-height: 1.3em;
}

h4 {
  font-weight: 300;
  font-size: 20px;
}

p {
  font-size: 15px;
  line-height: 1.65em;
  font-weight: 300;
}

.line::before,
.line::after {
  background-color: #000;
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  margin: 0 auto;
  min-width: 50px;
}

.line::before {
  margin-bottom: 2px;
}

@media (max-width: 1160px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 35px;
  }
}
